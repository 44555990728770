import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    Preparar-te per a l'oposició al <strong>Cos de Mossos d'Esquadra</strong>{" "}
                    requereix un gran compromís, esforç i una dedicació constant. Però no has de
                    fer-ho sol: estem aquí per ajudar-te a <strong>aconseguir l'èxit</strong> amb
                    les millors eines d'estudi.
                </p>

                <p>
                    El nostre objectiu és proporcionar-te un recurs que et permeti{" "}
                    <strong>dominar ràpidament</strong> els continguts de la prova de coneixements,
                    fent la teva preparació més àgil i efectiva per superar totes les fases de
                    l'oposició.
                </p>

                <p>
                    Amb aquest llibre, tindràs accés a <strong>resums concisos</strong>, esquemes
                    visuals i taules informatives, que t'ajudaran a assimilar el temari oficial de
                    la Generalitat de Catalunya de forma clara i ordenada. Hem seleccionat les idees
                    clau i les preguntes més rellevants dels exàmens anteriors, incloent-hi les de
                    l'any 2017, per garantir que estiguis preparat amb els millors materials.
                </p>

                <p>
                    Triar les eines adequades és fonamental per a l'èxit en una oposició. Per això,
                    hem creat aquest llibre aplicant tècniques d'estudi provades, perquè tu puguis
                    preparar-te de manera <strong>més eficient i ràpida</strong>.
                </p>

                <p>
                    No esperis més! Comença a preparar-te amb <strong>seguretat i confiança</strong>
                    , i fes que el teu somni d'entrar al Cos de Mossos d'Esquadra sigui una
                    realitat.
                </p>

                <div className="rounded p-5 border shadow-md mt-5">
                    <embed
                        src={require("@static/documents/products/453/preview.pdf")}
                        type="application/pdf"
                        className=""
                        width="100%"
                        height="750px"
                    />
                </div>

                <p className="text-sm">
                    No pots visualitzar la prèvia? Fes clic{" "}
                    <Link
                        href={require("@static/documents/products/453/preview.pdf")}
                        target="_blank"
                        rel="nopener noreferrer"
                        className="text-cpurple font-semibold hover:text-ccyan duration-300"
                    >
                        aquí
                    </Link>
                    .
                </p>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Prepara't amb eficàcia per a l'oposició de Mossos d'Esquadra amb el nostre llibre d'esquemes visuals i resums clau, dissenyat per simplificar el teu estudi i portar-te més a prop de l'èxit."
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
